import React from 'react'

class Home extends React.Component {
  render() {
      return (
          < >
              <div className="paper container margin-bottom-small">
                  <p className="text-lead">Firma tegeleb raudteede ehituse ja remontiga alates 1999.a.</p>
                  <p>
                      Raudteerajatise ehitamine. Raudteede hooldus. Üldehitus.
                  </p>
                  <p>
                      Kehtib kvaliteedijuhtimissüsteemi sertifikaat vastavalt ISO9001:2015 standardile.
                      {/*<a href="images/juhtimissert.23.1.jpg" className="image-link smashing-link">*/}
                      {/*    <>ISO 9001:2015 sertifikaat </>*/}

                          {/*<img src="./images/juhtimissert.23.tn-sq.jpg"*/}
                          {/*     title="ISO 9001:2015 sertifikaat"*/}
                          {/*     alt="ISO 9001:2015 sertifikaat"*/}
                          {/*     width="100rem" className="shadow "*/}
                          {/*/>*/}
                      {/*</a>*/}

                  </p>

              </div>

              <div className='paper container margin-bottom-small'>
                  <h4>Meie pakutavad teenused:</h4>


                  {
                      this.teenusedData().map((val, index) =>
                          ( <div key={index+1} style={{'line-height': '1.7'}}>{index+1}. {val}</div> )
                      ) }
              </div>


              <div className='paper container margin-bottom-small'>
                  <div className="row flex-top">
                      <div className="col lg-6 md-6 sm-12">
                          <table>
                              <tbody>
                              <tr>
                                  <td>
                                      Aadress: Näituse 26, 50407 Tartu
                                  </td>
                              </tr>
                              <tr>
                                  <td>e-mail: ferrander@gmail.com</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                      <div className="col lg-6 md-6 sm-12">
                          <table>
                              <tbody>
                              <tr>
                                  <td>
                                      Reg.nr. 10608381
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      KMRK nr. EE100581238
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>


              </div>
          </>

      )
  }

  teenusedData() {
      return [
          'Raudteede ehitus, remont ja hooldus',
          'Ülesõidukohtade ning pöörangute käsitsi lumest puhastamine',
          'Raudtee ülesõidukohtade ja kergeliikluse ülekäigukohtade ehitamine',
          'Raudtee pealisehitise materjalide müük',
          'Raudtee teemehaanik, tase 4/5 teenused',
          'Raudteede demonteerimine',
          'Raudtee kaitsevööndis üldehitustööd',
          'Kraanateede ehitus-, remondi- ja hooldustööd',
          'Haruraudteede seisundi hindamine',
          'Raudteede ja pöörmete lumest puhastamine raudteeekskavaatoriga',
      ]
  }


}

export default Home
