import React from 'react';
import Home from './Home'
import Tooted from './Tooted'
import {BrowserRouter, Route, NavLink, Routes} from 'react-router-dom'
// import './inc/layout.css.off'
import './inc/south_style.css'
import './inc/paper.css'
import './inc/magnific-popup.css'
import $ from 'jquery';
import 'magnific-popup'



class App extends React.Component {


render() {
    return (
                <BrowserRouter basename="/" >
                        <div id="header" className="row flex-middle ">
                            <div className="sm-12 col" align="center" >
                                <img src="images/rels24sINVERT.JPG" alt="//" className="no-border"/>

                            </div>
                            {/*<div className="sm-2 col"/>*/}

                        </div>

                    <div className="paper container margin-bottom-large">

                        <div className="row flex-middle">
                            <div className="lg-1 sm-2 col" align="right"></div>
                            <div className="lg-5 sm-4 col">

                                <NavLink to="/" className="button-link"  >
                                    { ({ isActive }) => (
                                        <button
                                            className={ (isActive ? "btn-block btn-primary " : "btn-block ")  }>
                                            Firmast
                                        </button>
                                    )}
                                    {/*<button className="btn-block">*/}
                                    {/*    Firmast*/}
                                    {/*</button>*/}
                                </NavLink>
                            </div>
                            <div className="lg-5 sm-4 col">
                                <NavLink to="/tooted" >
                                    { ({ isActive }) => (
                                        <button
                                            className={ (isActive ? "btn-block btn-primary " : "btn-block ")  }>
                                            Tehtud tööd
                                        </button>
                                    )}
                                </NavLink>
                            </div>

                            <div className="lg-1 col"/>
                        </div>
                        <div className="row flex-middle">

                        </div>

                        <Routes>
                                <Route exact path="/" element={<Home />}/>

                                {/*<Route path="/teenused" component={Teenused}/>*/}
                                <Route path="/tooted" element={<Tooted />}/>
                                {/*<Route path="/kontakt" component={Kontakt}/>*/}
                        </Routes>
                    </div>


                </BrowserRouter>
);
}
componentDidMount () {
    $(document).ready(function() {
      if ($('.image-link').length > 0) {
          $('.image-link').magnificPopup({type: 'image', class: 'no-border', image: {verticalFit: false}});
      }
    });
}

}

export default App
